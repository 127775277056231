<template>
    <v-container fluid>
        <PageHeaderSlot>
            <template slot="top-right-slot">
                <v-btn text depressed class="primary ml-2" :to="{ name: 'CreateCourseCategory' }">
                    <v-icon size="20" class="mr-3">{{ icon.mdiPlusCircleOutline }}</v-icon>
                    新增分類
                </v-btn>
            </template>
        </PageHeaderSlot>

        <Datatable
            :isLoading="tableLoading"
            :tableHeaders="tableHeaders"
            :tableData="tableData"
            :page="tablePage"
            :pageLimit="tableLimit"
            :itemTotal="tableItemTotal"
            @options-update="onTableOptionsChange"
            actionViewRouteLink="CourseCategoryDetails"
        ></Datatable>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPlusCircleOutline } from '@mdi/js';
import Datatable from '@/components/Datatable.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name: 'CourseCategoryList',
    components: {
        Datatable,
        ConfirmDialog
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,

        //-- static
        icon: {
            mdiPlusCircleOutline
        },
        tableHeaders: [
            { value: 'category_code', text: '課程分類編號' },
            { value: 'category_name', text: '課程分類' },
            { value: 'tutor_school', text: '分校' },
            { value: 'actionView', text: '', align: 'end', width: 90 },
        ],
    }),
    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog']),
        async getCourseCategoryData() {
            this.tableLoading = true;
            try {
                let payload = {
                    filter_limit: this.tableLimit,
                    filter_page: this.tablePage - 1,
                    join_school_data: true,
                };
                const selectedSchool = await this.getUserSelectedSchool();
                if (this.$validate.DataValid(selectedSchool)) {
                    payload.center_id = selectedSchool;
                }

                const { data, total } = await this.$Fetcher.GetCourseCategories(payload);
                this.tableData = data.map(el => {
                    const schoolNames = this.$validate.DataValid(el.school_datas) ? el.school_datas.map(el => el.name) : [];
                    return {
                        id: el.id,
                        category_code: el.category_code,
                        category_name: el.name,
                        tutor_school: schoolNames.join(', ')
                    }
                })
                
                this.tableItemTotal = total;
            } catch(err) {
                this.$common.error(err);
                this.tableData = [];
            } finally {
                this.tableLoading = false;
            }
        },
        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getCourseCategoryData();
        },
    },
    async created() {
        await this.getCourseCategoryData();
    }
}
</script>